import type { ComponentType } from "react"
import { useEffect } from "react"

export function hideFramerBadge(Component): ComponentType {
    return (props) => {
        useEffect(() => {
            // Hide the badge container
            const badgeContainer = document.getElementById(
                "__framer-badge-container"
            )
            if (badgeContainer) {
                badgeContainer.style.display = "none"
                badgeContainer.remove()
            }

            // Remove the comment from the DOM
            const comments = document.createTreeWalker(
                document,
                NodeFilter.SHOW_COMMENT,
                null,
                false
            )
            let comment
            while ((comment = comments.nextNode())) {
                if (comment.nodeValue?.includes("Built with Framer")) {
                    comment.parentNode?.removeChild(comment)
                }
            }
        }, [])

        return <Component {...props} />
    }
}
